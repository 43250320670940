import React, { useState, useEffect } from 'react';
import { Box } from '@primer/react';
import { Code, Monitor, MonitorPlay, XCircle } from 'phosphor-react';
import CustomBlankslate from '@/components/Elements/CustomBlankSlate/CustomBlankSlate';

interface Container {
  _id: string;
  gpu_status: string;
  gpu: string;
}

interface Cluster {
  _id: string;
  status: string;
  mode: string;
  containers: Container[];
}

interface Pod {
  _id: string;
  name: string;
  clusters: Cluster[];
}

interface PodsStatusProps {
  allPodsSSE: Pod[];
}

export const PodsStatus: React.FC<PodsStatusProps> = ({ allPodsSSE }) => {
  const [podsState, setPodsState] = useState<Pod[]>([]); // Current state of the pods

  // Utility function to deep compare two objects (pods data)
  const deepCompare = (obj1: any, obj2: any) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  // Check if the SSE data has changed, and update the state only when there's a change
  useEffect(() => {
    if (!deepCompare(allPodsSSE, podsState)) {
      setPodsState(allPodsSSE); // Update the state if data has changed
    }
  }, [allPodsSSE, podsState]);

  // Function to get the count of containers for a specific mode (api, comfyui, etc.)
  const getContainersStatusCount = (clusters: Cluster[], mode: string) => {
    const filteredClusters = clusters?.filter((cluster) => cluster?.mode === mode);
    const containers = filteredClusters?.flatMap((cluster) => cluster?.containers || []);
    const validContainers = containers?.filter((container) => container?.gpu); // if want to remove any container
    const idleCount = validContainers?.filter(
      (container) => container?.gpu_status === 'idle'
    ).length;
    const runningCount = validContainers.filter(
      (container) =>
        container?.gpu_status === 'running' ||
        container?.gpu_status === 'initializing' ||
        container?.gpu_status === 'cold-starting'
    )?.length;
    const stoppedCount = validContainers.filter(
      (container) => container?.gpu_status === 'stopped'
    )?.length;

    return { idle: idleCount, running: runningCount, stopped: stoppedCount };
  };

  // Function to check if any container is running for a specific mode
  const isAnyContainerRunning = (clusters: Cluster[], mode: string) => {
    const filteredClusters = clusters?.filter((cluster) => cluster?.mode === mode);
    const containers = filteredClusters?.flatMap((cluster) => cluster?.containers || []);
    const validContainers = containers?.filter((container) => container?.gpu);
    return validContainers?.some(
      (container) =>
        container?.gpu_status === 'running' ||
        container?.gpu_status === 'initializing' ||
        container?.gpu_status === 'cold-starting'
    );
  };

  // Function to display status indicators for API server or ComfyUI
  const getContainersDisplay = (idleCount: number, runningCount: number, stoppedCount: number) => {
    return (
      <div className="flex items-center ml-2 gap-0.5">
        {Array.from({ length: runningCount }).map((_, index) => (
          <span
            key={index}
            className="h-2 w-2 rounded-full border border-green-500 bg-green-500"
          ></span>
        ))}
        {Array.from({ length: idleCount }).map((_, index) => (
          <span
            key={index}
            className="h-2 w-2 rounded-full border border-yellow-500 bg-yellow-500"
          ></span>
        ))}
        {Array.from({ length: stoppedCount }).map((_, index) => (
          <span
            key={index}
            className="h-2 w-2 rounded-full border border-gray-500 bg-gray-500"
          ></span>
        ))}
      </div>
    );
  };

  return (
    <Box className="flex flex-col">
      {podsState?.length === 0 || !podsState ? (
        <Box className="flex justify-center items-center my-7 h-full w-full">
          <CustomBlankslate
            icon={<XCircle size={34} />}
            heading="No pods found"
            description="There are no pods available."
          />
        </Box>
      ) : (
        podsState?.map((pod, index) => {
          const apiStatus = getContainersStatusCount(pod.clusters, 'api');
          const comfyUIStatus = getContainersStatusCount(pod.clusters, 'comfyui');
          const isApiRunning = isAnyContainerRunning(pod.clusters, 'api');
          const isComfyUIRunning = isAnyContainerRunning(pod.clusters, 'comfyui');
          const isAnyRunning = isApiRunning || isComfyUIRunning;

          return (
            <Box
              key={pod?._id}
              className={`p-4 ${index !== podsState.length - 1 ? 'border-b border-gray-700' : ''}`}
            >
              <h3 className="font-bold text-xs flex items-center">
                {isAnyRunning ? (
                  <span className="h-2 w-2 rounded-full bg-green-500 mr-2"></span>
                ) : (
                  <Monitor size={18} className="mr-2" />
                )}
                {`${pod?.name}`}
              </h3>

              {/* Clusters and Containers Status */}
              <Box className="mt-3 text-xs">
                {/* ComfyUI Workspace Status */}
                <Box className="flex items-center gap-2 mt-2">
                  <span className="text-xs flex items-center border bg-[#16191D] border-gray-700 px-3 py-1 rounded-xl">
                    <MonitorPlay size={14} className="mr-2" />
                    ComfyUI Workspace
                    {getContainersDisplay(
                      comfyUIStatus.idle,
                      comfyUIStatus.running,
                      comfyUIStatus.stopped
                    )}
                  </span>
                </Box>

                {/* API Server Status */}
                <Box className="flex items-center gap-2 mt-2">
                  <span className="text-xs flex items-center border bg-[#16191D] border-gray-700 px-3 py-1 rounded-xl">
                    <Code size={14} className="mr-2" />
                    API Server
                    {getContainersDisplay(apiStatus.idle, apiStatus.running, apiStatus.stopped)}
                  </span>
                </Box>
              </Box>
            </Box>
          );
        })
      )}
    </Box>
  );
};
